import {
  useMutation,
  useQuery,
  useLazyQuery,
  type UseMutationReturn,
  type UseQueryReturn,
} from "@vue/apollo-composable";
import type { UseLazyQueryReturn } from "@vue/apollo-composable/dist/useLazyQuery.js";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import hash from "object-hash";

type SharedMutations = { [key: string]: UseMutationReturn<any, any> };
type SharedQueries = { [key: string]: UseQueryReturn<any, any> };
type SharedLazyQueries = { [key: string]: UseLazyQueryReturn<any, any> };

const sharedMutations = {} as SharedMutations;
const sharedQueries = {} as SharedQueries;
const sharedLazyQueries = {} as SharedLazyQueries;

export default () => {
  const useSharedMutation = <TData, TVariables>(
    definition: TypedDocumentNode<TData, TVariables>
  ) => {
    const key = hash.MD5(definition);
    if (sharedMutations[key]) return sharedMutations[key];
    sharedMutations[key] = useMutation(definition);
    return sharedMutations[key];
  };

  const useSharedQuery = <TData, TVariables>(
    definition: TypedDocumentNode<TData, TVariables>
  ) => {
    const key = hash.MD5(definition);
    if (sharedQueries[key]) return sharedQueries[key];
    sharedQueries[key] = useQuery(definition);
    return sharedQueries[key];
  };

  const useSharedLazyQuery = <TData, TVariables>(
    definition: TypedDocumentNode<TData, TVariables>
  ) => {
    const key = hash.MD5(definition);
    if (sharedLazyQueries[key]) return sharedLazyQueries[key];
    sharedLazyQueries[key] = useLazyQuery(definition);
    return sharedLazyQueries[key];
  };

  return {
    useSharedMutation,
    useSharedQuery,
    useSharedLazyQuery,
  };
};
